<script>
  import { GridContainer, Grid, Link, Icon } from "@ObamaFoundation/of-design-system";

  export let title;
  export let bodyText;
  export let additionalInformationLink;
  export let showClose = true;
  export let isHidden = false;

  const handleClose = () => {
    isHidden = true;
  };
</script>

<div
  class="w-full bottom-0 fixed z-[--stack-level-layer-top] left-0 bg-white py-5 lg:py-10 border-t-[2px]"
  class:hidden={isHidden}
  data-testid="banner"
  aria-labelledby="banner-title"
  aria-describedby="banner-body"
  role="dialog"
>
  <GridContainer>
    <Grid>
      <div class="col-full-width flex flex-row justify-between w-full pb-5">
        {#if title}
          <h2 id="banner-title" class="heading-xs">
            {title}
          </h2>
        {/if}
        {#if showClose}
          <Link
            on:click={handleClose}
            variant="inline"
            on:keypress={handleClose}
            aria-label="Close dialog"
          >
            <Icon name="close" />
          </Link>
        {/if}
      </div>
      <div id="banner-body" class="col-full-width body-sm md:col-span-8">
        <div class="">
          <p>{bodyText}</p>
          <div class="mt-5">
            {#if additionalInformationLink}
              <Link
                data-sveltekit-reload
                variant="inline"
                data-cy="additional-information-link"
                class="!body-sm"
                href={additionalInformationLink.fields.url}
              >
                {additionalInformationLink.fields.text}
              </Link>
            {/if}
          </div>
        </div>
      </div>
      <div
        class="col-full-width md:col-span-4 md:col-start-9 mt-5 md:mt-0 md:flex md:justify-end md:items-end"
      >
        <div class="md:flex md:items-end">
          <slot />
        </div>
      </div>
    </Grid>
  </GridContainer>
</div>
