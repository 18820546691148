<script>
  import algoliasearch from "algoliasearch/lite";
  import instantsearch from "instantsearch.js";
  import aa from "search-insights";
  import index from "instantsearch.js/es/widgets/index/index";
  import {
    connectSearchBox,
    connectHits,
    connectAutocomplete,
    connectConfigure
  } from "instantsearch.js/es/connectors";
  import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
  import {
    acceptingCookie,
    getGoogleAnalyticsUserIdFromBrowserCookie
  } from "$lib/utilities/cookies";

  /** @type boolean */
  export let isLoading;
  /**
   * @callback QueryUpdater
   * @param {string} query
   *
   * @type {QueryUpdater} hook for algolia searchBox `refine` func
   */
  export let updateQuery;

  /**
   * hook for algolia suggestions `refine` func
   */
  export let updateSuggestion;

  /** @type {Array} */
  export let results;

  /** @type {Array}*/
  export let suggestions;

  /**
   * @callback HitTransformer
   * @param {object} hit
   *
   * @type {HitTransformer} helper func that transform hits into renderable results
   */
  export let transformHit;

  /**
   * DOM query selector for all elements that render search results
   *
   * @type {string}
   */
  export let hitRendererSelector;

  /**
   * DOM query selector for all elements that render search suggestions
   *
   * @type {string}
   */
  export let suggestionRendererSelector;

  const indexName = import.meta.env.VITE_ALGOLIA_INDEX_NAME;
  const suggestionIndexName = import.meta.env.VITE_ALGOLIA_SUGGESTION_INDEX_NAME;
  const appId = import.meta.env.VITE_ALGOLIA_APP_ID;
  const apiKey = import.meta.env.VITE_ALGOLIA_API_KEY;

  if (!(indexName && suggestionIndexName && appId && apiKey)) {
    throw new Error("Missing environment variables required for search!");
  }

  const searchClient = algoliasearch(appId, apiKey);

  const search = instantsearch({
    searchClient,
    indexName,
    searchFunction(helper) {
      // prevent search on page load
      if (helper.state.query) {
        helper.search();
      }
    },
    insights: true,
    future: {
      preserveSharedStateOnUnmount: false
    }
  });

  /**
   * virtual renderer for search box
   * @param renderOptions
   * @param isFirstRender
   */
  const searchBoxRenderer = (renderOptions, isFirstRender) => {
    const { refine } = renderOptions;

    if (isFirstRender) {
      updateQuery = refine;
    }

    return null;
  };

  /**
   * virtual renderer for hits
   *
   * @param renderOptions
   */
  const hitsRenderer = (renderOptions) => {
    const { hits, sendEvent } = renderOptions;

    if (hits) {
      isLoading = false;
      results = hits.map(transformHit);
    }

    // attach a click handler to every search result
    const elements = document.querySelectorAll(hitRendererSelector);
    elements?.forEach((element, index) => {
      element.addEventListener("click", (event) => {
        event.stopImmediatePropagation();
        // fire off click events to insights middleware
        sendEvent("click", hits[index], "Hit Clicked");
      });
    });

    return null;
  };

  /**
   * virtual renderer for suggestions
   *
   * @param renderOptions
   * @param isFirstRender
   */
  const suggestionsRenderer = (renderOptions) => {
    const { hits, sendEvent } = renderOptions;

    suggestions = hits;

    // attach a click handler to every suggestion
    const suggestionElements = document.querySelectorAll(suggestionRendererSelector);
    suggestionElements?.forEach((element, index) => {
      element.addEventListener("click", (event) => {
        event.stopImmediatePropagation();
        sendEvent("click", suggestions?.[index], "Suggestion Clicked");
      });
    });

    return;
  };

  const autocompleteRenderer = async (renderOptions, isFirstRender) => {
    const { refine } = renderOptions;

    if (isFirstRender) {
      updateSuggestion = refine;
    }

    return null;
  };

  search.addWidgets([
    connectConfigure()({
      searchParameters: {
        page: 0,
        hitsPerPage: 44 * 2
      }
    }),
    connectSearchBox(searchBoxRenderer)({}),
    connectHits(hitsRenderer)({}),
    index({ indexName: suggestionIndexName }).addWidgets([
      connectAutocomplete(autocompleteRenderer)({}),
      connectHits(suggestionsRenderer)({})
    ])
  ]);

  const userToken = getGoogleAnalyticsUserIdFromBrowserCookie("_ga");
  const insightsMiddleware = createInsightsMiddleware({
    insightsClient: aa,
    insightsInitParams: {
      useCookie: acceptingCookie(),
      partial: true
    },
    onEvent: (event, aa) => {
      const { insightsMethod, payload, widgetType, eventType } = event;

      // send the event to Algolia
      if (insightsMethod) {
        aa(insightsMethod, payload);
      }

      // send the event to GTM
      if (
        ["ais.hits", "ais.autocomplete"].includes(widgetType) &&
        ["view", "click"].includes(eventType)
      ) {
        window.dataLayer?.push({ event: payload.eventName });
      }
    }
  });
  search.use(insightsMiddleware);
  aa("setUserToken", userToken);

  search.start();
</script>

<slot />
