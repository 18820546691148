<script>
  import "../app.css";

  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import { onMount, setContext } from "svelte";
  import { configureScope } from "@sentry/browser";

  import GTM from "$lib/components/GTM.svelte";
  import Header from "$lib/components/Header.svelte";
  import Footer from "$lib/components/Footer.svelte";
  import { Button, DynamicHeadingContext } from "@ObamaFoundation/of-design-system";
  import FundraiseUpRedirects from "$lib/components/FundraiseUpRedirects.svelte";
  import VWO from "$lib/components/VWO.svelte";
  import WithBanner from "$lib/components/WithBanner.svelte";

  export let data;

  let fundraiseUpScript;
  let viewportHeight;
  let topBannerHeight = 0;

  onMount(async () => {
    // Until we fix hydration, there's no reason to check for this.  It must run when the page is loaded.
    //if (import.meta.env.MODE !== 'production') {
    //}

    //Set mode to correct live mode before adding script to the page.
    window.fundraiseup_livemode = import.meta.env.VITE_FUNDRAISE_UP_LIVE_MODE === "true"; // if true, FundraiseUp is live
    fundraiseUpScript = "https://cdn.fundraiseup.com/widget/AHNVSXNQ";
  });

  // add feature flags to context
  setContext("featureFlags", data.featureFlags);

  configureScope((scope) => scope.setTransactionName(`${$page?.url?.pathname}`));

  //In the future, once identity is in place, we can also link users to this
  //data via the following:
  //Sentry.setUser({ email: "jane.doe@example.com" });
  //}
</script>

<svelte:window bind:innerHeight={viewportHeight} />

<svelte:head>
  {#if fundraiseUpScript}
    <script async src={fundraiseUpScript}></script>
  {/if}
</svelte:head>

<VWO />

<FundraiseUpRedirects redirects={data.fruRedirects} />

<DynamicHeadingContext>
  <div data-headings-start>
    <Button
      id="skip-to-content"
      url="#main"
      theme="white"
      label="Skip to content"
      variant="filled"
      buttonClass="!absolute !z-[--stack-level-layer-top] !left-4 !top-[-100px] focus-visible:!top-4 hover:bg-white"
    />

    <GTM cookieBanner={data.cookieBanner} />

    <WithBanner data={data.alertBanner} bind:topBannerHeight>
      <Header data={data.header} activePrimaryUrl={data.activePrimaryUrl} {topBannerHeight} />
    </WithBanner>

    <main
      id="main"
      tabindex="-1"
      class="flex-1 relative focus:outline-none min-h-screen"
      data-headings-increment=""
    >
      <slot />
    </main>

    <Footer data={data.footer} />
  </div>
</DynamicHeadingContext>
