<script>
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-browser
  import { afterNavigate } from "$app/navigation";

  export let redirects = {};

  $: currentUrl = $page?.url;
  afterNavigate(async () => {
    if (redirects[currentUrl.pathname] && !currentUrl.searchParams.get("form")) {
      window.location.href = `${currentUrl.origin}${redirects[currentUrl.pathname]}`;
    }
  });
</script>
