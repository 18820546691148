<script>
  // @ts-check
  import { getContext } from "svelte";

  import { GlobalFooter, Link } from "@ObamaFoundation/of-design-system";

  import {
    srcSetFromWidths,
    sizesForBreakpoints,
    SRCSET_WIDTHS,
    contentfulImageUrlFor
  } from "$lib/utilities";
  import { isExternalUrl } from "$lib/utilities/url-helpers";

  export let data;

  const themeName = "cobaltBlue";

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  const ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  const navigationTargetItems = data?.children?.filter((i) => i.contentType === "navTarget");

  const footerColumnsRaw = data?.children?.filter(
    (i) => i.contentType === "menu" || i.contentType === "linkComponent"
  );

  const parseMenuItem = (menuItem) => {
    const title = menuItem.fields?.trigger?.fields?.text;
    const links = menuItem.children.map((l) => {
      return {
        id: `gtm-${l.url.substring(1, l.url.length - 1).replaceAll("/", "-")}-${l.sys.id}`,
        href: l.url,
        text: l.text,
        isExternal: isExternalUrl(l.url)
      };
    });

    return {
      type: "links",
      title,
      links
    };
  };

  const parseLinkComponentItem = (linkComponent) => {
    return {
      type: "card",
      card: {
        label: linkComponent.text,
        imageUrl: linkComponent.image?.fields.file.url,
        srcset: !ignoreContentfulImgResize
          ? srcSetFromWidths(linkComponent.image?.fields.file.url, SRCSET_WIDTHS)
          : null,
        sizes: sizesForBreakpoints({
          sm: "100vw",
          md: "50vw",
          lg: "25vw"
        }),
        alt: linkComponent.image?.fields.description,
        linkUrl: linkComponent.destination?.fields.url,
        isExternal: isExternalUrl(linkComponent.destination?.fields.url),
        id: `primary-nav-utility-${linkComponent.sys.id}`
      }
    };
  };

  const footerColumns =
    footerColumnsRaw?.map((c) => {
      if (c.contentType === "linkComponent") {
        return parseLinkComponentItem(c);
      }
      return parseMenuItem(c);
    }) || [];

  const donateButtonData = navigationTargetItems?.find((i) => i.name === "Donate");

  const footerTitleImageUrl =
    "//images.ctfassets.net/l7h59hfnlxjx/5qLOagBRPSEvvpWDVsl0jd/a70a8374e6c0e3bbb0c6291da2376ba6/1204374f849dd17ba86168f3905700fc";

  const footerProps = {
    themeName,
    title: [
      {
        type: "text",
        content: "Donate",
        class: "text-[35vw] md:text-[16vw] xl:text-[16.5vw]" // adding default for reference, may need to update font sizes for various breakpoints when changing this text
      },
      {
        type: "image",
        content: contentfulImageUrlFor(footerTitleImageUrl),
        class: "w-[85vw] md:w-[17.75vw] pl-[1.5vw] md:pl-0", // may need to update image sizes for various breakpoints when surrounding text is changed
        srcset: !ignoreContentfulImgResize
          ? srcSetFromWidths(footerTitleImageUrl, SRCSET_WIDTHS)
          : null,
        sizes: sizesForBreakpoints({ sm: "100vw", md: "25vw" })
      },
      {
        type: "text",
        content: "Today",
        class: "text-[35vw] md:text-[16vw] xl:text-[16.5vw]" // adding default for reference, may need to update font sizes for various breakpoints when changing this text
      }
    ],
    subTitle:
      "We need your help to turn hope into action—to inspire, empower, and connect people to change their world.",
    ctaButton: {
      label: donateButtonData?.text,
      href: donateButtonData?.url,
      id: "footer-donate"
    },
    footerColumns: [...footerColumns],
    socialLinks: [
      { href: "https://www.facebook.com/obamafoundation", iconName: "facebook" },
      { href: "https://www.instagram.com/obamafoundation/?hl=en", iconName: "instagram" },
      { href: "https://www.threads.net/@obamafoundation", iconName: "threads" },
      { href: "https://www.linkedin.com/company/obama-foundation", iconName: "linkedIn" },
      { href: "https://www.youtube.com/obamafoundation", iconName: "youtube" },
      { href: "https://x.com/obamafoundation", iconName: "x" }
    ],
    legalLinks: [
      { href: "/terms-of-service", text: "Terms of service" },
      { href: "/privacy-statement", text: "Privacy policy" }
    ]
  };
</script>

<GlobalFooter {...footerProps}>
  <div slot="bottomText">
    <p class="rich-text rich-text-paragraph">
      The Obama Foundation is a nonpartisan 501(c)(3) nonprofit organization on a mission to
      inspire, empower, and connect people to change their world.
      <Link
        class="hover:text-white"
        href="/501c3-disclaimer"
        label="Read more"
        variant="inline"
        theme={themeName}
        isExternal={false}
      />
      &nbsp;about our tax-exempt status.
    </p>
    <p class="rich-text rich-text-paragraph">
      &copy; {new Date().getFullYear()} The Barack Obama Foundation.
    </p>
  </div>
</GlobalFooter>
