<script>
  import { onMount } from "svelte";
  import { setCookie, getCookie, getAllCookies, deleteCookie } from "$lib/utilities/cookies";
  import { Button, Link } from "@ObamaFoundation/of-design-system";
  import Banner from "./Banner.svelte";
  import { PRIVACY_MANDATE_REGIONS, REQUIRED_COOKIES } from "$lib/utilities/constants";

  export let gtmContainerId = import.meta.env.VITE_GTM_CONTAINER_ID;
  export let gtmDebugParams = import.meta.env.VITE_GTM_DEBUG_PARAMS;
  export let scriptSrc;

  export let cookieBanner;

  const defaultDataRedaction = true;
  const consentTypes = [
    "ad_storage",
    "analytics_storage",
    "personalization_storage",
    "functionality_storage",
    "security_storage"
  ];

  //This truly does not work properly with rest params.
  function gtag() {
    /* eslint-disable-next-line prefer-rest-params */
    window.dataLayer.push(arguments);
  }

  function onAcceptSubmit() {
    setCookie("_of", "accept", 180);
    isCookieBannerVisible = false;
    gtag("consent", "update", buildConsentMap("granted"));

    // dynamically update algolia search insights cookie settings
    window.aa?.("init", {
      useCookie: true,
      partial: true
    });
  }

  function onRejectSubmit() {
    setCookie("_of", "reject", 180);
    isCookieBannerVisible = false;
    deleteCookies();
    gtag("consent", "update", buildConsentMap("denied"));

    // dynamically update algolia search insights cookie settings
    window.aa?.("init", {
      useCookie: false,
      partial: true
    });
  }

  function deleteCookies() {
    Object.keys(getAllCookies()).forEach((cookie) => {
      if (!REQUIRED_COOKIES.includes(cookie)) {
        deleteCookie(cookie);
      }
    });
  }

  const buildConsentMap = (consentValue) => {
    return consentTypes.reduce((hash, elem) => {
      hash[elem] = consentValue;
      return hash;
    }, {});
  };

  //This component has some complexity meant to support more indepth information
  //to be captured by the tag manager at a later date.
  const getScriptSrcFromInitGtm = () => {
    const getScriptSrcForGtm = (containerId, params) => {
      if (typeof containerId !== "string" || !containerId.length) {
        // eslint-disable-next-line no-console
        console.error("GTM container id variable is not a string or is empty");
        return;
      } else {
        let script = `https://www.googletagmanager.com/gtm.js?id=${containerId}`;
        if (params) {
          script += `&${params}`;
        }

        return script;
      }
    };

    try {
      window.dataLayer = window.dataLayer || [];

      let action = getCookie("_of");
      if (action === "accept") {
        gtag("consent", "default", buildConsentMap("granted"));
      } else if (action === "reject") {
        deleteCookies();

        let consentMap = buildConsentMap("denied");
        gtag("consent", "default", consentMap);
      } else {
        deleteCookies();

        //Deny all cookie storage types in regions where this is prohibited.
        let consentMap = buildConsentMap("denied");
        consentMap.region = PRIVACY_MANDATE_REGIONS;
        gtag("consent", "default", consentMap);
      }

      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js"
      });
      gtag("set", "url_passthrough", true);
      gtag("set", "ads_data_redaction", defaultDataRedaction);
      //If other items are needed for GTM later, we can add them here

      isCookieBannerVisible = !action;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Google Tag Manager.", error);
    }

    return getScriptSrcForGtm;
  };

  let isCookieBannerVisible = false;

  onMount(() => {
    scriptSrc = getScriptSrcFromInitGtm()(gtmContainerId, gtmDebugParams);
  });
</script>

<svelte:head>
  {#if scriptSrc}
    <script async src={scriptSrc}></script>
  {/if}
</svelte:head>

<!-- Google Tag Manager (noscript) -->
<noscript>
  <iframe
    title="gtm"
    src="https://www.googletagmanager.com/ns.html?id={gtmContainerId}&{gtmDebugParams}"
    height="0"
    width="0"
    style="display:none;visibility:hidden"
  />
</noscript>
<!-- End Google Tag Manager (noscript) -->

{#if cookieBanner}
  <Banner
    isHidden={!isCookieBannerVisible}
    title={cookieBanner.fields?.headline}
    bodyText={cookieBanner.fields?.text}
    additionalInformationLink={cookieBanner.fields?.link}
  >
    <div class="flex flex-row gap-10 md:items-center md:justify-end self-end h-full">
      <Link
        variant="small"
        on:click={onRejectSubmit}
        data-testid="reject-cookies"
        hideInternalLinkArrow={true}
      >
        Reject
        <span class="sr-only">Cookies</span>
      </Link>
      <Button variant="filled" on:click={onAcceptSubmit} data-testid="accept-cookies">
        Accept
        <span class="sr-only">Cookies</span>
      </Button>
    </div>
  </Banner>
{/if}
