<script>
  import { Icon } from "@ObamaFoundation/of-design-system";

  export let focusOutlineColor = "";
  export let hoverTextColor = "";
</script>

<button
  on:click
  data-transition-focus="searchButton"
  class="grid place-items-center {focusOutlineColor} {hoverTextColor}} h-[24px] w-[24px] focus-visible:outline-dashed focus-visible:outline-offset-2"
  aria-label="Search"
>
  <span class="sr-only"> Search </span>
  <Icon name="search" />
</button>
