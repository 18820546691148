<script context="module">
  /**
   * determine whether to show or hide certain elements based on current path and an inclusion/exclusion list
   *
   * @param {string} currentPath
   * @param {string[]} pagesToShow
   * @param {string[]} pagesToHide
   */
  export function calculateVisibility(currentPath, pagesToShow = [], pagesToHide = []) {
    if (!pagesToShow.length && !pagesToHide.length) {
      return true;
    }

    if (!pagesToHide.length) {
      return pagesToShow.includes(currentPath);
    }

    if (!pagesToShow.length) {
      return !pagesToHide.includes(currentPath);
    }

    return pagesToShow.includes(currentPath) && !pagesToHide.includes(currentPath);
  }
</script>

<script>
  // @ts-check
  import { onMount } from "svelte";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import { Banner } from "@ObamaFoundation/of-design-system";

  import { BANNER_COOKIE_NAME } from "$lib/utilities/constants";
  import { getCookie, setCookie } from "$lib/utilities/cookies";
  import { textToId } from "$lib/utilities/dom-helpers";
  import { isExternalUrl } from "$lib/utilities/url-helpers";

  export let data;
  export let topBannerHeight = 0;

  const {
    headline: title,
    copy: body,
    cta: ctaData,
    variant,
    theme: themeName,
    pagesToShow,
    pagesToHide
  } = data?.fields || {};
  const cta = ctaData
    ? {
        text: ctaData.fields?.text,
        url: ctaData.fields?.destination?.fields?.url,
        isExternal: isExternalUrl(ctaData.fields?.destination?.fields?.url),
        id: `${variant}-banner-cta-${textToId(title)}`
      }
    : null;
  $: shouldShowOnCurrentPage = calculateVisibility($page?.url?.pathname, pagesToShow, pagesToHide);

  let dismissed = true;
  onMount(() => {
    dismissed = getCookie(BANNER_COOKIE_NAME) === "dismissed";
  });
  const handleClose = () => {
    setCookie(BANNER_COOKIE_NAME, "dismissed", 1);
  };
</script>

{#if shouldShowOnCurrentPage && variant === "top"}
  <Banner {variant} {title} {body} {cta} {themeName} bind:height={topBannerHeight} />
{/if}

<slot />

{#if shouldShowOnCurrentPage && variant === "bottom"}
  <Banner {variant} {title} {body} {cta} {themeName} on:close={handleClose} isHidden={dismissed} />
{/if}
